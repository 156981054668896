<template>
    <modal :name="modal_name" class="final-modal" transition="nice-modal-fade">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">CALCULATION</span>
                <button type="button" class="closeModal" aria-label="Close" @click="hidePours()">
                    <span aria-hidden="true">
                        <i class="icon icon-close"></i>
                    </span>
                </button>
            </div>
            <div class="v-modal-body pt-0 pb-0">
                <div class="v-modal-layout d-form" v-if='product'>
                    <div class="m-6 mb-13">
                        <div class="border-radius bg-secondary pt-3 pb-3">
                            <h6 class="font-weight-bold text-white text-center">POURS = QUANTITY / POUR SIZE</h6>
                        </div>
                        <div class="d-flex mt-7">
                            <div class="quantity-details bx-shadow">
                                <span class="font-weight-bold d-block">Quantity</span>
                                <span class="text-secondary d-block pt-3">{{ product.variant_quantity}} {{product.uom}}</span>
                            </div>
                            <div class="quantity-details pl-2 pr-2">
                                <span class="font-weight-bold d-block">Pour Size</span>
                                <span class="text-secondary pt-3 d-block">{{ product.measure}} {{product.uom}}</span>
                            </div>
                        </div>
                        <div class="final-cost d-flex pl-3 pr-3 pt-2 mt-7 justify-content-around">
                            <h6 class="font-weight-bold">POURS</h6>
                            <h6 class="text-secondary font-weight-bold">{{ product.variant_quantity / product.measure }}</h6>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </modal>
</template>
<script>
export default {
    props:['modal_name', 'product'],
    methods:{
        hidePours() {
            this.$modal.hide(this.modal_name);
        }    
    },
    created(){
        console.log(this.product)
    }
}
</script>
<style scoped>
.border-radius{
    border-radius: 12px;
}
.quantity-details{
    flex: 1 1 250px;
    font-size: 16px;
    text-align: center;
}
.bx-shadow{
    box-shadow: 3px 0 0px -2px #989898;
}
.final-cost{
    border-top: solid 1px #989898;
    border-bottom: solid 1px #989898;
}
</style>